<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Passport Logs</div>
        <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
            <div class="service-plan-box-outer">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="call-label">Total Charges : </div>
                    <div class="call-count ps-1"><span v-if="!loading">{{ totalCharges ? totalCharges : 0 }}</span>
                        <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-icon-loader-btn
                                  " role="status" v-if="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="passportogFilterModalOpen()" class="header-filter-btn-outer btn btn-light"
                title="Filter">
                <span class="custom-filter-apply-alert"></span>
                <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
        <DataTable :value="passportLogsList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
            :rows="10" :lazy="true" dataKey="la1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template v-if="!loading" #empty>No records found.</template>
            <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
            <Column field="datatime" header="Sent Date Time" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div>
                        {{ format_timestamp(data.dh8) }}
                    </div>
                </template>
            </Column>
            <Column field="name" header="Name" headerStyle="width: 20%" bodyStyle="width: 21%">
                <template #body="{ data }">
                    <div>
                        {{ data.dh3 ? data.dh3 : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="passport" header="Passport Number" headerStyle="width: 27%" bodyStyle="width: 27%">
                <template #body="{ data }">
                    <div class="text-uppercase">
                        {{ maskedNumber(data.dh4) ? maskedNumber(data.dh4) : "N/A" }}
                    </div>
                </template>
            </Column>
            <Column field="charges" header="Charges" headerStyle="width: 17%" bodyStyle="width: 17%">
                <template #body="{ data }">
                    <div>{{ data.dh10 && data.dh9 != 0 ? data.dh10 : "To Be Billed" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
    <!-- filter start here -->
    <div class="modal-mask" v-if="passportlogfiltermodalsflag">
        <div class="
        modal-sm modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" class="btn-close" @click="passportlogFilterModalClose()"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">From Date</label>
                                <Calendar v-model="fromdate" :showIcon="true" class="custom-ultima-calendar"
                                    placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :maxDate="todaysDate"
                                    :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">To Date</label>
                                <Calendar v-model="todate" :disabled="fromdate == null" :showIcon="true"
                                    class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy"
                                    :minDate="fromdate" :maxDate="todaysDate" :manualInput="false" :monthNavigator="true"
                                    :yearRange="'2020:' + new Date().getFullYear()" appendTo="body" :yearNavigator="true" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Passport Number</label>
                                <input type="passport" v-model="dh4" class="form-control"
                                    placeholder="Enter Passport Number" maxlength="15" autocomplete="off" />
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="custom-form-group">
                                <label class="form-label">Charges Status</label>
                                <div class="custom-group-radio-box-btn">
                                    <input type="radio" class="btn-check" v-model="dh9" name="gender-option" id="freecost"
                                        autocomplete="off" value="1" />
                                    <label class="btn custom-radio-box-btn text-capitalize" for="freecost">Free of
                                        Cost</label>
                                    <input type="radio" class="btn-check" v-model="dh9" name="gender-option" id="chargeble"
                                        autocomplete="off" value="2" />
                                    <label class="btn text-capitalize" for="chargeble">Chargeble</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="col-12 m-0">
                        <div class="d-flex justify-content-between">
                            <button class="btn filter-apply-btn"
                                :disabled="fromdate == null && todate == null && dh4 == '' && dh9 == ''" @click="
                                    btnFilterSearch(dh4, dh9)
                                    ">
                                Apply Filter
                            </button>
                            <button class="btn btn-link filter-reset-btn" @click="resetPanFilter">
                                Reset All
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- filter end here -->
</template>
<script>
import commonMixin from "../../../mixins/commonMixin.js";
import ApiService from "../../../service/ApiService";
import moment from 'moment';
export default {
    mixins: [commonMixin],
    data() {
        return {
            passportLogsList: null,
            totalRecords: 0,
            page_no: 0,
            loading: false,
            passportlogfiltermodalsflag: false,
            todaysDate: new Date(),
            dh4: '',
            dh9: '',
            fromdate: null,
            todate: null,
            totalCharges: 0,
        };
    },
    mounted() {
        this.getPassportApiLogs({
            page_no: this.page_no,
        });
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        getPassportApiLogs(e) {
            this.loading = true;
            this.ApiService.getPassportApiLogs(e).then((items) => {
                if (items.status == 200) {
                    this.passportLogsList = items.data;
                    this.totalRecords = items.count;
                    this.totalCharges = items.totalCharges;
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.passportLogsList = null;
                    this.totalCharges = 0;
                    this.totalRecords = 0;
                }
            });
        },
        changePage(event) {
            this.page_no = event.page;
            this.getPassportApiLogs({
                page_no: this.page_no, fromdate: this.fromdate,
                todate: this.todate, dh4: this.dh4,
                dh9: this.dh9
            });
        },
        format_timestamp(value) {
            if (value) {
                var localTime = new Date(value * 1000).toUTCString();
                return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
            } else {
                return 'N/A';
            }
        },
        passportogFilterModalOpen() {
            this.passportlogfiltermodalsflag = true;
            if (this.fromdate != null) {
                this.fromdate = new Date(this.fromdate);
            }
            if (this.todate != null) {
                this.todate = new Date(this.todate);
            }
        },
        passportlogFilterModalClose() {
            this.passportlogfiltermodalsflag = false;
        },
        btnFilterSearch(dh4, dh9) {
            if (this.fromdate != null && this.todate != null) {
                this.fromdate = moment(this.fromdate).format('YYYY-MM-DD');
                this.todate = moment(this.todate).format('YYYY-MM-DD');
            }
            this.dh4 = dh4;
            this.dh9 = dh9;
            this.passportlogfiltermodalsflag = false;
            this.getPassportApiLogs({
                page_no: this.page_no,
                fromdate: this.fromdate,
                todate: this.todate,
                dh4: this.dh4,
                dh9: this.dh9,
            });
        },
        resetPanFilter() {
            this.fromdate = null;
            this.todate = null;
            this.dh4 = '';
            this.dh9 = '';
            this.getPassportApiLogs({
                page_no: this.page_no,
            });
            this.passportlogfiltermodalsflag = false;
        },
    },
};
</script>
<style scoped>
.status-active {
    background: #c8e6c9;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #256029;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #4daa53;
    line-height: 12px;
}

.status-inactive {
    background: #ffcdd2;
    border-radius: 2px;
    letter-spacing: 0.3px;
    color: #c63737;
    opacity: 1;
    font-size: 12px;
    font-family: "AcuminPro-Bold";
    padding: 0.3em 0.2rem 0.2em 0.2rem;
    text-transform: capitalize;
    border: 1px solid #d7a5a5;
    line-height: 12px;
}

.profile-header-avatar {
    background: #b3bae1 0% 0% no-repeat padding-box;
    padding: 2px;
}

.service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
}

.service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
}

.service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}</style>